<template>
  <div class="finance-overview">
    <app-header :title="title" :isShowBack='true' @refresh="onRefresh"></app-header>

    <app-overview title="冲正后详情" :list="list1"></app-overview>
    <app-overview title="原始冲正详情" :list="list2"></app-overview>
    <app-overview title="账单冲正记录" :list="list3"></app-overview>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      title: "订单详情",
      list1: [
        {
          label: "订单编号",
          key: 'orderNoAfter',
          value: ""
        },
        {
          label: "订单金额（元）",
          key: 'orderAmountAfter',
          value: ""
        },
        {
          label: "交易编号",
          key: 'transactionNoAfter',
          value: ""
        },
        {
          label: "到账金额（元）",
          key: 'payAmountAfter',
          value: ""
        },
        {
          label: "到账时间",
          key: 'payDateAfter',
          value: ""
        },
        {
          label: "对账状态",
          key: 'reconStateAfter',
          value: ""
        },
        {
          label: "对账时间",
          key: 'reconDateAfter',
          value: "",
          isRow: true
        }
      ],
      list2: [
        {
          label: "订单编号",
          key: 'orderNoBefore',
          value: ""
        },
        {
          label: "订单金额（元）",
          key: 'orderAmountBefore',
          value: ""
        },
        {
          label: "交易编号",
          key: 'transactionNoBefore',
          value: ""
        },
        {
          label: "到账金额（元）",
          key: 'payAmountBefore',
          value: ""
        },
        {
          label: "到账时间",
          key: 'payDateBefore',
          value: ""
        },
        {
          label: "对账状态",
          key: 'reconStateBefore',
          value: ""
        },
        {
          label: "对账时间",
          key: 'reconDateBefore',
          value: "",
          isRow: true
        }
      ],
      list3: [
        {
          label: "冲正人用户名",
          key: 'userid',
          value: ""
        },
        {
          label: "冲正项",
          key: 'correctItem',
          value: ""
        },
        {
          label: "冲正人姓名",
          key: 'username',
          value: ""
        },
        {
          label: "冲正金额",
          key: 'correctAmount',
          value: ""
        },
        {
          label: "冲正人电话",
          key: 'phoneNumber',
          value: ""
        },
        {
          label: "凭证",
          value: "查看",
          on(item){
            console.log(item);
          }
        },
        {
          label: "冲正时间",
          value: ""
        },
        {
          label: "备注",
          key: 'remark',
          value: ""
        }
      ]
    };
  },
  activated () {
    this.id = this.$route.params.id;
    console.log("statement-order created!!");
    this.onGet();
  },
  methods: {
    onRefresh() {
      console.log("refresh");
    },
    onSubmit() {
      console.log(this.form);
      for (let i = 0; i < this.buts.length; i++) {
        let item = this.buts[i];
        item.type = "primary ";
      }
    },
    onSelect(idx) {
      console.log(idx);
      for (let i = 0; i < this.buts.length; i++) {
        let item = this.buts[i];
        if (i == idx) {
          item.type = "success";
        } else if (item.type == "success") {
          item.type = "primary ";
        }
      }
    },

    onGet(opt) {
      let dto = {
        orderNo: this.id
      };
      this.post("/finance-service/reconStatisticsDay/searchReconLeaseOrderCorrectDetail", dto, {
        isUseResponse: true
      }).then(res => {
        var data = res.data.data;
        var keys = []
        for (var key in data) {
          keys.push(key);
        }
        this.list1.forEach(item => {
          if (keys.indexOf(item['key']) != -1)  {
            item.value = data[keys[keys.indexOf(item['key'])]];
          }
        })
        this.list2.forEach(item => {
          if (keys.indexOf(item['key']) != -1)  {
            item.value = data[keys[keys.indexOf(item['key'])]];
          }
        })
        this.list3.forEach(item => {
          if (keys.indexOf(item['key']) != -1)  {
            item.value = data[keys[keys.indexOf(item['key'])]];
          }
        })
      });
    }
  }
};
</script>
<style lang="scss">
.finance-overview {
  .row-label {
    text-align: right;
  }

  .data-box {
    margin-bottom: 30px;
    max-width: 1200px;

    .data-table {
      color: #606266;
      border: solid 1px #e4e7ed;
      font-size: 14px;

      .row-label {
        background-color: #ebeef5;
      }
      .el-col {
        padding: 10px;
        height: 40px;
        line-height: 20px;
        border-left: solid 1px #e4e7ed;
      }

      .el-row {
        border-bottom: solid 1px #e4e7ed;
      }

      .el-col:nth-child(1),
      .el-row:nth-last-child(1) {
        border-bottom: none;
      }
    }

    .data-title {
      margin-bottom: 20px;
      border-bottom: solid 1px #e4e7ed;
      text-align: center;
      span {
        position: relative;
        padding: 0 10px;
        top: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #909399;
        background-color: #ffffff;
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "finance-overview" },
    [
      _c("app-header", {
        attrs: { title: _vm.title, isShowBack: true },
        on: { refresh: _vm.onRefresh }
      }),
      _c("app-overview", { attrs: { title: "冲正后详情", list: _vm.list1 } }),
      _c("app-overview", { attrs: { title: "原始冲正详情", list: _vm.list2 } }),
      _c("app-overview", { attrs: { title: "账单冲正记录", list: _vm.list3 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }